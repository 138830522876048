import React, { FC, useState, useEffect } from 'react';

import UpLeftArrow from '@mui/icons-material/NorthWest';
import Send from '@mui/icons-material/Send';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { usePostMessageMutation } from 'services/api/messages';
import { Client } from 'services/types/client';
import { Message } from 'services/types/message';

interface Props {
  client: Client;
  conversation: Message[] | undefined;
  setConversation: (messages: Message[]) => void;
}

const ChatFooter: FC<Props> = ({ client, conversation, setConversation }) => {
  const [active, setActive] = useState(false);
  const [messageToSend, setMessage] = useState('');
  const [postMessageMutation, { data: newMessage }] = usePostMessageMutation();

  const handleSendMessage = () => {
    // eslint-disable-next-line no-console
    postMessageMutation({ clientId: client.id, text: messageToSend }).catch((err) => console.error(err));
    setMessage('');
  };

  useEffect(() => {
    if (conversation) {
      const yesterday = new Date();
      yesterday.setHours(new Date().getHours() - 24);
      if (conversation.find((message) => message.role === 'user' && new Date(message.createdAt) > yesterday)) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
  }, [conversation]);

  useEffect(() => {
    if (newMessage && conversation) {
      setConversation([...conversation, newMessage]);
    }
  }, [newMessage]);

  if (!active) {
    return (
      <Grid container style={{ padding: '20px' }} alignItems="center" flexDirection="column">
        {conversation?.length === 0 ? (
          <Typography>No se ha hablado con este usuario, envíe una plantilla para comenzar una conversación</Typography>
        ) : (
          <Typography>Chat no está activo, pasaron más de 24 horas desde el último contacto del usuario</Typography>
        )}
        <Typography>Puedes enviar un template usando las opciones del chat</Typography>
        <UpLeftArrow fontSize="small" sx={{ ml: 1 }} />
      </Grid>
    );
  }
  return (
    <Grid container width="100%" p={2} justifyContent="space-between">
      <TextField
        id="outlined-basic-email"
        label="Mensaje"
        fullWidth
        multiline
        sx={{
          maxWidth: '90%',
          backgroundColor: '#fff',
          borderRadius: '40px',
          opacity: 1,
          mr: 1,
          '& .MuiOutlinedInput-root': { borderRadius: '40px' },
        }}
        value={messageToSend}
        onChange={(e) => setMessage(e.target.value)}
      />
      <IconButton
        sx={{
          height: '3.5rem',
        }}
        onClick={handleSendMessage}
      >
        <Send
          sx={{
            fontSize: '2.5rem',
          }}
        />
      </IconButton>
    </Grid>
  );
};

export default ChatFooter;
