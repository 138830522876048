import React, { useEffect, useState, FC } from 'react';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import SectionHeader from 'components/layout/SectionHeader';
// import dbservice
import { useAdminExecuteQueryMutation, useAdminGenerateAIQueryMutation } from 'services/api/admin-db.api';
import type { DbOutput } from 'services/api/admin-db.api';

const CellComponent: FC<{ value: unknown }> = (props: { value: unknown }) => {
  const { value } = props;
  if (typeof value === 'string' && value.startsWith('http')) {
    return (
      <a href={value} target="_blank" rel="noreferrer">
        {value}
      </a>
    );
  }
  if (typeof value === 'object') {
    return <div>{JSON.stringify(value)}</div>;
  }
  if (typeof value === 'boolean') {
    return <div>{value ? 'true' : 'false'}</div>;
  }
  return <div>{value}</div>;
};

const DbReader = () => {
  const [userQuery, setUserQuery] = useState<string>('');
  const [sqlQuery, setSQLQuery] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [generateAIQuery, { data: aiQueryData, isLoading }] = useAdminGenerateAIQueryMutation();
  const [executeQuery, { data: queryData, error, isLoading: isLoadingResult }] = useAdminExecuteQueryMutation();

  const handleGenerateAIQuery = () => {
    generateAIQuery({ userQuery }).catch((err) => console.error(err)); // eslint-disable-line no-console
  };

  const handleExecuteQuery = () => {
    executeQuery({ userQuery: sqlQuery }).catch((err) => console.error(err)); // eslint-disable-line no-console
  };

  useEffect(() => {
    if (aiQueryData) {
      setSQLQuery(aiQueryData.sqlQuery);
      setDescription(aiQueryData.description);
    }
  }, [aiQueryData]);

  useEffect(() => {
    console.log(queryData); // eslint-disable-line no-console
  }, [queryData]);

  useEffect(() => {
    if (error) {
      console.error(error); // eslint-disable-line no-console
      const alertMessage = `Error al ejecutar la consulta, revisa la consola para más información.
Revisa las comillas en los nombres de las tablas y columnas, y que las columnas existan en las tablas.`;
      alert(alertMessage); // eslint-disable-line no-alert
    }
  }, [error]);

  const downloadDataAsCSV = () => {
    if (queryData) {
      let fileData = `${Object.keys(queryData[0]).join(';')}\n`;
      fileData += queryData
        .slice(1)
        .map((row) => Object.values(row).join(';'))
        .join('\n');
      const blob = new Blob([fileData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'db-data.csv';
      link.href = url;
      link.click();
    }
  };

  const rows =
    queryData?.map((data, id) => ({
      ...data,
      id: data.id && typeof data.id === 'number' ? `${id}: ${data.id}` : id,
    })) ?? [];
  const columns =
    queryData && queryData.length > 0
      ? Object.keys(queryData[0]).map(
          (key): GridColDef<DbOutput> => ({
            field: key,
            headerName: key,
            // Min width in characters: max(10, max length of the column). Max width: 80. Approx 10 px per character
            width:
              12 *
              Math.min(
                Math.max(
                  10,
                  ...queryData.map(
                    (row) =>
                      (typeof row[key] === 'object'
                        ? JSON.stringify(row[key])
                        : (row[key] as string | number)?.toString()
                      )?.length || 0
                  )
                ),
                80
              ),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderCell: (params: GridRenderCellParams<any, string>) => <CellComponent value={params.value} />,
            type: 'string',
          })
        )
      : [];

  return (
    <div>
      <SectionHeader text="Analizador de Base de Datos" button={null} />
      {/* Add one input for the user query */}
      <Box width="100%">
        <TextField
          fullWidth
          value={userQuery}
          onChange={(e) => setUserQuery(e.target.value)}
          placeholder="Escribe lo que quieres preguntarle a la base de datos"
          label="Consulta en español"
          sx={{ marginBottom: '1rem' }}
        />
        {/* add spinner for loading query */}
        {isLoading && <Typography>Cargando...</Typography>}
        <Button onClick={handleGenerateAIQuery} sx={{ marginBottom: '1rem' }} variant="contained">
          Generar consulta
        </Button>
        <TextField
          fullWidth
          value={sqlQuery}
          rows={5}
          multiline
          onChange={(e) => setSQLQuery(e.target.value)}
          placeholder="Escribe la consulta que quieres ejecutar"
          label="Consulta SQL"
        />
        <Typography fontSize="13px" pb="10px" mt={0} pt={0}>
          {description}
        </Typography>
        {/* Add one button to execute the query */}
        {isLoadingResult && <Typography>Cargando...</Typography>}
        <Button onClick={handleExecuteQuery} disabled={!sqlQuery} variant="contained">
          Ejecutar consulta
        </Button>
        {/* Show the results */}
        {queryData && queryData.length > 0 && (
          <Box pt={2}>
            <Box display="flex" pb={2}>
              <Typography variant="h6">Resultados</Typography>
              <Button onClick={downloadDataAsCSV} sx={{ ml: 2 }} variant="contained">
                Descargar como CSV
              </Button>
            </Box>
            <DataGrid
              autoHeight
              rows={rows}
              columns={columns}
              sx={{
                '& .MuiDataGrid-cell': {
                  fontSize: '14px',
                },
                color: 'black',
              }}
            />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default DbReader;
