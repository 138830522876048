import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { RemoveRedEyeOutlined } from '@mui/icons-material';
import Send from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import DealershipSelector from 'components/common/DealershipsSelector';
import useCurrentUser from 'hooks/useCurrentUser';
import { useGetAnalysesQuery, useCreateAnalysisMutation } from 'services/api/analyses.api';

const Analyses = () => {
  const { data: analyses } = useGetAnalysesQuery(null);
  const navigate = useNavigate();
  const [createAnalysis, { data }] = useCreateAnalysisMutation();
  const [messageToSend, setMessageToSend] = useState<string>('');
  const [dealershipId, setDealershipId] = useState<number | null>(null);
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (currentUser && currentUser.dealershipIds && currentUser.dealershipIds.length > 0) {
      setDealershipId(currentUser.dealershipIds[0]);
    }
  }, [currentUser]);

  const handleNewAnalysis = () => {
    if (!messageToSend) return;
    createAnalysis({ query: messageToSend, dealershipId: dealershipId ?? undefined }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error creating analysis', error);
    });
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if inside a form
      handleNewAnalysis();
    }
  };

  useEffect(() => {
    if (data) {
      navigate(`/analysis/${data.id}`);
    }
  }, [data]);

  const isDisabled = !dealershipId;

  return (
    <Box>
      <Box display="flex" justifyContent="flex-start" m={2} alignItems="center">
        <Typography variant="h4">Análisis</Typography>
        <Box display="flex" justifyContent="flex-start" m={2} ml={6} alignItems="center">
          <DealershipSelector
            dealershipId={dealershipId}
            setDealershipId={setDealershipId}
            disableAllDealershipsOption
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" m={2} mt={0}>
        <Box display="flex" width="600px" m={2} mt={0} flexDirection="row">
          <Grid container justifyContent="flex-start">
            <TextField
              disabled={isDisabled}
              id="outlined-basic-email"
              placeholder="Escribe un mensaje para hacer un nuevo análisis"
              multiline
              helperText="Ej: Llegó un Kia Sorento usado a $10.000.000, a quien le puede interesar?, ¿Quiénes se enojaron con el vendedor?, ¿Cuantos encontraron muy caras las mantenciones?, ¿Cuántos clientes llegaron cada mes?, ¿Quienes han escrito más veces?"
              sx={{
                width: '85%',
                backgroundColor: '#fff',
                borderRadius: '10px',
                opacity: 1,
                mr: 1,
                '& .MuiOutlinedInput-root': { borderRadius: '10px' },
              }}
              onKeyPress={handleKeyPress}
              value={messageToSend}
              onChange={(e) => setMessageToSend(e.target.value)}
            />
            <IconButton
              sx={{
                height: '3.5rem',
              }}
              onClick={handleNewAnalysis}
              disabled={isDisabled || !messageToSend}
            >
              <Send
                sx={{
                  fontSize: '2.5rem',
                }}
              />
            </IconButton>
          </Grid>
        </Box>
      </Box>

      <Grid container>
        {analyses
          ?.filter((analysis) => !dealershipId || dealershipId === analysis.dealershipId)
          .map((analysis) => (
            <Card
              key={analysis.id}
              sx={{ margin: 2, borderRadius: 2, maxWidth: '30%', boxShadow: '2px 2px 7px 2px rgba(0, 0, 0, 0.2);' }}
            >
              <CardHeader
                title={analysis.query}
                action={
                  <Link to={`/analysis/${analysis.id}`}>
                    <Button>
                      <RemoveRedEyeOutlined />
                    </Button>
                  </Link>
                }
              />
              <CardContent>
                <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                  {analysis.history.length > 0
                    ? analysis.history[analysis.history.length - 1].content?.slice(0, 600)
                    : 'Sin mensajes'}
                </Typography>
              </CardContent>
            </Card>
          ))}
      </Grid>
    </Box>
  );
};

export default Analyses;
