import React, { FC } from 'react';

import { Box, TextField } from '@mui/material';

import { SearchIcon } from 'assets/icons';
import DealershipSelector from 'components/common/DealershipsSelector';

interface Props {
  searchTerm: string;
  onSearchTermChange: (searchTerm: string) => void;
  dealershipId: number | null;
  setDealershipId: (dealershipId: number) => void;
}

const SearchBar: FC<Props> = ({ searchTerm, onSearchTermChange, dealershipId, setDealershipId }) => {
  return (
    <Box flexDirection="row" display="flex">
      <TextField
        type="text"
        placeholder="Buscar"
        onChange={(e) => onSearchTermChange(e.target.value)}
        value={searchTerm}
        sx={{
          '& .MuiInputBase-input': {
            fontSize: '14px',
            color: 'text.secondary',
            fontWeight: 'bold',
          },
          '& .MuiInputBase-root': {
            border: '1px solid #E5E5E5',
            borderRadius: '10px',
            padding: '0 10px',
          },
          backgroundColor: 'primary.contrastText',
          mx: 2,
        }}
        InputProps={{
          endAdornment: <SearchIcon />,
        }}
      />
      <DealershipSelector dealershipId={dealershipId} setDealershipId={setDealershipId} />
    </Box>
  );
};

export default SearchBar;
