import { Dealership } from 'services/types/dealership';

import { SignInBody, SignInResponse, User } from '../types/user';
import baseApi from './baseApi';

const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    signInUser: build.mutation({
      query: (body: SignInBody) => ({
        url: '/auth',
        method: 'POST',
        body,
      }),
      transformResponse: (response: SignInResponse) => response,
    }),
    getUser: build.query({
      query: () => ({
        url: '/users/user',
        method: 'GET',
      }),
      transformResponse: (response: User): User => response,
    }),
    getUserDealerships: build.query({
      query: () => ({
        url: '/user/dealerships',
        method: 'GET',
      }),
      transformResponse: (response: Dealership[]): Dealership[] => response,
    }),
    getDealershipSources: build.query({
      query: (props: { dealershipId: number | null }) => ({
        url: `/user/dealership/sources?dealershipId=${props.dealershipId || ''}`,
        method: 'GET',
      }),
      transformResponse: (response: string[]): string[] => response,
    }),
    getDealershipBrands: build.query({
      query: (props: { dealershipId: number | null }) => ({
        url: `/user/dealership/brands?dealershipId=${props.dealershipId || ''}`,
        method: 'GET',
      }),
      transformResponse: (response: string[]): string[] => response,
    }),
    updatePassword: build.mutation({
      query: (body: { currentPassword: string; newPassword: string }) => ({
        url: '/user/update-password',
        method: 'PUT',
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSignInUserMutation,
  useGetUserQuery,
  useGetUserDealershipsQuery,
  useGetDealershipSourcesQuery,
  useUpdatePasswordMutation,
  useGetDealershipBrandsQuery,
} = userApi;

export default userApi;
