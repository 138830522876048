import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { ScatterChart } from '@mui/x-charts/ScatterChart';

import type { GenerateChartToolArguments } from 'services/types/analysis';

import MessageWrapper from './MessageWrapper';

interface Props {
  args: string;
}

const GenerateChartToolComponent: FC<Props> = ({ args }: Props) => {
  const argumentsObject = JSON.parse(args) as GenerateChartToolArguments;
  const title = `Gráfico ${argumentsObject.yAxisLabel} vs ${argumentsObject.xAxisLabel}`;
  const longestLabel = argumentsObject.data.reduce(
    (acc, data) => (data.label.length > acc ? data.label.length : acc),
    5
  );
  return (
    <MessageWrapper caption={title} align="left">
      {argumentsObject.chartType === 'bar' && (
        <BarChart
          title={title}
          series={[
            {
              data: argumentsObject.data.map((data) => data.value),
            },
          ]}
          height={400}
          width={900}
          xAxis={[
            {
              data: argumentsObject.data.map((data) => data.label),
              scaleType: 'band',
              tickLabelStyle: longestLabel > 8 ? { angle: 45, textAnchor: 'start', fontSize: 12 } : undefined,
            },
          ]}
          yAxis={[
            {
              label: argumentsObject.yAxisLabel,
            },
          ]}
          margin={{ bottom: 20 + longestLabel * 6, left: 70 }}
          sx={{
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translate(-24px, 0)',
            },
          }}
        />
      )}
      {argumentsObject.chartType === 'line' && (
        <LineChart
          title={title}
          series={[
            {
              data: argumentsObject.data.map((data) => data.value),
            },
          ]}
          height={400}
          width={900}
          xAxis={[
            {
              data: argumentsObject.data.map((data) => data.label),
              scaleType: 'band',
              tickLabelStyle: longestLabel > 8 ? { angle: 45, textAnchor: 'start', fontSize: 12 } : undefined,
            },
          ]}
          yAxis={[
            {
              label: argumentsObject.yAxisLabel,
            },
          ]}
          margin={{ bottom: 20 + longestLabel * 6, left: 70 }}
        />
      )}
      {argumentsObject.chartType === 'pie' && (
        <PieChart
          skipAnomation
          label={title}
          title={title}
          series={[
            {
              data: argumentsObject.data.map((data) => ({ id: data.label, label: data.label, value: data.value })),
            },
          ]}
          width={800}
          height={(Math.floor(argumentsObject.data.length / 5) + 1) * 200}
        />
      )}
      {argumentsObject.chartType === 'scatter' && (
        <ScatterChart
          title={title}
          series={[
            {
              data: argumentsObject.data.map((data) => ({ id: data.label, x: Number(data.label), y: data.value })),
            },
          ]}
          height={400}
          width={800}
          xAxis={[
            {
              label: argumentsObject.xAxisLabel,
            },
          ]}
          yAxis={[
            {
              label: argumentsObject.yAxisLabel,
            },
          ]}
        />
      )}
      {['line', 'bar'].includes(argumentsObject.chartType) && (
        <Box justifyContent="center" display="flex">
          <Typography variant="body1">{argumentsObject.xAxisLabel}</Typography>
        </Box>
      )}
    </MessageWrapper>
  );
};

export default GenerateChartToolComponent;
