import { AIResponse, OpenAIFunction } from '../types/message';
import baseApi from './baseApi';

const playgroundApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    generateRequest: build.mutation({
      query: (body: { conversation: AIResponse[]; functions: OpenAIFunction[]; prompt: string; model: string }) => ({
        url: 'admin/playground/chatCompletion',
        method: 'POST',
        body,
      }),
      transformResponse: (response: AIResponse): AIResponse => response,
    }),
  }),
  overrideExisting: false,
});

// eslint-disable-next-line import/prefer-default-export
export const { useGenerateRequestMutation } = playgroundApi;
