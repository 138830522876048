import { Dealership, DealershipDraft, DealershipUpdate, DealershipCostMetrics } from 'services/types/dealership';

import baseApi from './baseApi';

const dealershipsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getDealerships: build.query({
      query: () => ({
        url: '/admin/dealerships',
        method: 'GET',
      }),
      transformResponse: (response: Dealership[]) => response,
    }),
    getDealership: build.query({
      query: (id: number) => ({
        url: `/admin/dealerships/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Dealership) => response,
    }),
    editDealership: build.mutation({
      query: (props: { dealership: DealershipUpdate; id: number }) => ({
        url: `admin/dealerships/${props.id}`,
        method: 'PATCH',
        body: props.dealership,
      }),
      transformResponse: (response: Dealership) => response,
    }),
    createDealership: build.mutation({
      query: (company: DealershipDraft) => ({
        url: `admin/dealerships`,
        method: 'POST',
        body: company,
      }),
      transformResponse: (response: Dealership) => response,
    }),
    deleteDealershipImage: build.mutation({
      query: (props: { dealershipId: number; fileName: string }) => ({
        url: `/admin/dealerships/${props.dealershipId}/images/${props.fileName}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => response,
    }),
    uploadDealershipImages: build.mutation({
      query: (props: { dealershipId: number; files: File[] }) => {
        const formData = new FormData();
        props.files.forEach((file) => {
          formData.append('file', file);
        });
        return {
          url: `/admin/dealerships/${props.dealershipId}/images`,
          method: 'POST',
          body: formData,
        };
      },
      transformResponse: (response) => response,
    }),
    adminGetDealershipCostCharts: build.mutation({
      query: (id: number) => ({
        url: `/admin/dealerships/${id}/cost-charts`,
        method: 'GET',
      }),
      transformResponse: (response: DealershipCostMetrics) => response,
    }),
    adminGetDealershipConversationTopics: build.mutation({
      query: (props: { dealershipId: number; startDate: string; endDate: string }) => ({
        url: '/admin/conversationsMetrics/conversations-metrics',
        method: 'POST',
        body: props,
      }),
      transformResponse: (response: {
        topics: Record<string, number>;
        endReasons: Record<string, number>;
        aiScores: Record<number, number>;
      }) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDealershipsQuery,
  useGetDealershipQuery,
  useEditDealershipMutation,
  useCreateDealershipMutation,
  useUploadDealershipImagesMutation,
  useDeleteDealershipImageMutation,
  useAdminGetDealershipCostChartsMutation,
  useAdminGetDealershipConversationTopicsMutation,
} = dealershipsApi;
