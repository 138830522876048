import { Message, SendMessage, SendTemplate } from '../types/message';
import baseApi from './baseApi';

const messagesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getClientMessages: build.query({
      query: (clientId: number) => ({
        url: `/clients/${clientId}/messages`,
        method: 'GET',
      }),
      transformResponse: (response: Message[]) => response,
    }),
    getMessage: build.query({
      query: (messageId: number) => ({
        url: `/admin/messages/${messageId}`,
        method: 'GET',
      }),
      transformResponse: (response: Message) => response,
    }),
    postMessage: build.mutation({
      query: (body: SendMessage) => ({
        url: `/clients/${body.clientId}/messages`,
        method: 'POST',
        body: {
          text: body.text,
        },
      }),
      transformResponse: (response: Message) => response,
    }),
    postTemplateMessage: build.mutation({
      query: (body: SendTemplate) => ({
        url: `/clients/${body.clientId}/template-messages`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: Message) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetClientMessagesQuery, useGetMessageQuery, usePostMessageMutation, usePostTemplateMessageMutation } =
  messagesApi;
