import type {
  DealershipClientsMetrics,
  DealershipMessagesMetrics,
  DealershipResponseTimesMetrics,
  DealershipFollowUpResponseTimesMetrics,
  GetDealershipMetricsParams,
} from '../types/metrics';
import baseApi from './baseApi';

const metricsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getClientsMetrics: build.mutation({
      query: (params: GetDealershipMetricsParams) => ({
        url: '/metrics/clients',
        method: 'POST',
        body: params,
      }),
      transformResponse: (response: DealershipClientsMetrics): DealershipClientsMetrics => response,
    }),
    getMessagesMetrics: build.mutation({
      query: (params: GetDealershipMetricsParams) => ({
        url: '/metrics/messages',
        method: 'POST',
        body: params,
      }),
      transformResponse: (response: DealershipMessagesMetrics): DealershipMessagesMetrics => response,
    }),
    getResponseTimesMetrics: build.mutation({
      query: (params: GetDealershipMetricsParams) => ({
        url: '/metrics/response-times',
        method: 'POST',
        body: params,
      }),
      transformResponse: (response: DealershipResponseTimesMetrics): DealershipResponseTimesMetrics => response,
    }),
    getFollowUpResponseTimesMetrics: build.mutation({
      query: (params: GetDealershipMetricsParams) => ({
        url: '/metrics/followup-response-times',
        method: 'POST',
        body: params,
      }),
      transformResponse: (response: DealershipFollowUpResponseTimesMetrics): DealershipFollowUpResponseTimesMetrics =>
        response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetClientsMetricsMutation,
  useGetMessagesMetricsMutation,
  useGetResponseTimesMetricsMutation,
  useGetFollowUpResponseTimesMetricsMutation,
} = metricsApi;
