import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { DeleteIcon } from 'assets/icons';
import { PrimaryBtn } from 'components/UI/Buttons';
import useIsAdmin from 'hooks/useIsAdmin';
import moment from 'moment';
import {
  useAdminGetDealershipCostChartsMutation,
  useDeleteDealershipImageMutation,
  useEditDealershipMutation,
  useGetDealershipQuery,
  useUploadDealershipImagesMutation,
  useAdminGetDealershipConversationTopicsMutation,
} from 'services/api/dealerships.api';
import priceFormat from 'services/format/priceFormat';

import ChartCard from '../Dashboard/components/ChartCard';

const PROMPT_HELP_TEXT = (
  <Box mt={4}>
    <Typography variant="h4">Instrucciones para el prompt</Typography>
    Dentro del prompt se pueden usar las siguientes variables:
    <br />
    <b>{'<CLIENT:attribute>'}</b>: Incluir un atributo del cliente. (Ej: {'<CLIENT:Rut>'})
    <br />
    <b>{'<CURRENT_DATE>'}</b>: Fecha actual
    <br />
    <b>{'<UF_PRICE>'}</b>: Valor actual de la UF
    <br />
    <b>{'<IF:attr>Texto</IF:attr>'}</b>: Incluye el &quot;Texto&quot; solo si existe el atributo
    <br />
    <b>{'<IF:!attr>Texto</IF:!attr>'}</b>: Incluye el &quot;Texto&quot; solo si el atributo no es válido, es falso o es
    cero.
    <br />
    <b>{'<IF:attr_operador_valor>Texto</IF:attr_operador_valor>'}</b>: Incluye el &quot;Texto&quot; solo si el atributo
    cumple la comparación.
    <br />
    <b>{'<WORKING_HOURS>Texto</WORKING_HOURS>'}</b>: Incluye el &quot;Texto&quot; solo si estamos en horario laboral
    <b>{'<OUT_OF_WORKING_HOURS>Texto</OUT_OF_WORKING_HOURS>'}</b>: Incluye el &quot;Texto&quot; solo si estamos fuera de
    horario laboral
    <br />
    <Box padding="20px">
      En <b>attr</b> puede ir <i>rut</i>, <i>email</i>, <i>salary</i>, <i>savingsAmount</i>, etc
      <br />
      En <b>operador</b> puede ir <i>=</i> (igual), <i>!=</i> (distinto), <i>&gt;</i> (mayor), <i>&lt;</i>, <i>&gt;=</i>
      , <i>&lt;=</i>, <i>startsWith</i>, <i>endsWith</i>, <i>contains</i>
      <br />
      En <b>valor</b> puede ir un número, un string, un true (Si) o un false (No)
      <br />
      Ejemplos:
      <ul>
        <li>{'<IF:email_contains_@salfa>Este es un empleado de Salfa, trátalo bien</IF:email_contains_@salfa>'}</li>
        <li>{'<IF:messagesCount_>_4><IF:!rut>Debes pedir el rut</IF:!rut></IF:messagesCount_>_4>'}</li>
        <li>
          {
            '<IF:nationality><IF:nationality_!=_CHILENA>Pregunta si tiene residencia definitiva</IF:nationality_!=_CHILENA></IF:nationality>'
          }
        </li>
        <li>
          {
            '<IF:clientMessagesCount_>_5>Es un cliente interesado, guíalo a visitar la sucursal</IF:clientMessagesCount_>_5>'
          }
        </li>
        <li>{'<IF:imagesSent_=_0>Pregúntale si quiere fotos</IF:imagesSent_=_0>'}</li>
        <li>{'<IF:imagesSent_>_0>Pregúntale si quiere más fotos</IF:imagesSent_>_0>'}</li>
        <li>{'<IF:floidRequestsCount_>_0>Tiene 1+ floid requests</IF:floidRequestsCount_>_0>'}</li>
      </ul>
      En attr se puede poner uno de los siguientes atributos (debe ser exacto, considerando las mayúsculas y minúsculas,
      no lo ocupes si no sabes qué significa):
      <ul>
        <li>email</li>
        <li>rut</li>
        <li>phone</li>
        <li>firstName</li>
        <li>lastName</li>
        <li>motherLastName</li>
        <li>savingsAmount</li>
        <li>notes</li>
        <li>adminNotes</li>
        <li>dateOfBirth</li>
        <li>gender</li>
        <li>nationality</li>
        <li>maritalStatus</li>
        <li>salary</li>
        <li>salarySupplement</li>
        <li>score</li>
        <li>lastMessageDate</li>
        <li>quote</li>
        <li>messagesCount</li>
        <li>imagesSent</li>
        <li>followUpsSent</li>
        <li>floidRequestsCount</li>
        <li>floidRequestsDetails</li>
        <li>isTestClient</li>
        <li>disabledAutomaticResponses</li>
        <li>source</li>
        <li>status</li>
        <li>userScore</li>
        <li>financialScore</li>
        <li>interestScore</li>
        <li>manualScore</li>
        <li>prospectMessage</li>
        <li>aiSafetyStatus</li>
      </ul>
      <a
        href="https://www.loom.com/share/c9e2f8008df44b82bf04eedf9ff95bd8?sid=f4404379-26c6-4421-8693-68f75a62b08e"
        target="_blank"
        rel="noreferrer"
      >
        Video instrucciones 1
      </a>
      <br />
      <a
        href="https://www.loom.com/share/8ca6921e97c74631bcee788ad8d1ca6c?sid=d04e35a1-3c09-45c1-a482-6fde8cda3397"
        target="_blank"
        rel="noreferrer"
      >
        Video instrucciones 2
      </a>
      <br />
      <a
        href="https://www.loom.com/share/8ff296ef858141d08c00654b635106f7?sid=ace50e83-e46e-407d-9a2b-335abd079198"
        target="_blank"
        rel="noreferrer"
      >
        Video instrucciones 3
      </a>
    </Box>
  </Box>
);

const Dealerships = () => {
  const { dealershipId } = useParams();
  const { data: dealership, refetch } = useGetDealershipQuery(Number(dealershipId));
  const [prompt, setPrompt] = useState<string>('');
  const [chatRevisionPrompt, setChatRevisionPrompt] = useState<string | null>(null);
  const [refetchFlag, setRefetchFlag] = useState<boolean>(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [updateDealerShip] = useEditDealershipMutation();
  const [deleteDealerShipImage] = useDeleteDealershipImageMutation();
  const [imagesToUpload, setImagesToUpload] = useState<File[] | null>(null);
  const [uploadDealershipImages] = useUploadDealershipImagesMutation();
  const [getDealershipCostsCharts, { data: dealershipCostsCharts }] = useAdminGetDealershipCostChartsMutation();
  const [functions, setFunctions] = React.useState<string[]>([]);
  const availableFunctions = [
    'sendCarImage',
    'sendImages',
    'sendVideo',
    'saveAndValidateEmail',
    'saveAndValidateRut',
    'calculateCreditForAmount',
    'calculateAutoCreditForAmount',
    'findNewCarsForClient',
    'findUsedCarsForClient',
    'sendQuote',
    'getPompeyoClient',
    'savePompeyoClient',
    'getPompeyoClientCars',
    'savePompeyoClientCar',
    'getAvailability',
    'getAvailableHours',
    'scheduleHour',
    'getCarServices',
    'getServicesCosts',
    'saveClientName',
    'getBranchOffices',
    'scheduleVisit',
    'scheduleDealershipVisit',
    'calculateForumCreditForAmount',
    'registerQuestionWithoutKnownResponse',
    'saveFinancingStatus',
    'saveWantsToBeContacted',
    'getCarMonthlyFee',
    'getForumCreditStatus',
    'getBankOffers',
    'createDebt',
    'saveWantsToBeContactedKaufmann',
  ];
  const currentDate = new Date();
  const dateBeginningOfMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
  const dateEndOfMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  const [initialDate, setInitialDate] = React.useState<moment.Moment>(dateBeginningOfMonth);
  const [endDate, setEndDate] = React.useState<moment.Moment>(dateEndOfMonth);

  const [getTopics, { data: conversationTopics }] = useAdminGetDealershipConversationTopicsMutation();

  const isAdmin = useIsAdmin();

  const handleUpdate = () => {
    if (!dealership) return;
    updateDealerShip({
      id: dealership.id,
      dealership: {
        prompt,
        availableFunctions: functions,
        chatRevisionPrompt,
      },
    }).catch((err) => console.log(err)); // eslint-disable-line
  };

  const navigate = useNavigate();

  const handleDeleteImage = (fileName: string) => {
    if (dealership) {
      deleteDealerShipImage({ dealershipId: dealership.id, fileName })
        .then(() => {
          setRefetchFlag(!refetchFlag);
        })
        .catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  };

  const handleUploadImages = () => {
    if (imagesToUpload && dealership) {
      uploadDealershipImages({ dealershipId: dealership.id, files: imagesToUpload })
        .then(() => {
          setRefetchFlag(!refetchFlag);
        })
        .catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  };

  const getImagesError = () => {
    if (!imagesToUpload || !imagesToUpload.length) {
      return 'No hay imágenes seleccionadas';
    }
    if (imagesToUpload.some((file) => file.size > 300_000)) {
      return 'Hay imágenes que pesan más de 300kb';
    }
    return null;
  };

  const handleChange = (event: SelectChangeEvent<typeof functions>) => {
    const {
      target: { value },
    } = event;
    setFunctions(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  useEffect(() => {
    if (dealership) {
      setPrompt(dealership.prompt);
      setFunctions(dealership.availableFunctions || []);
      setChatRevisionPrompt(dealership.chatRevisionPrompt || null);
    }
  }, [dealership]);

  useEffect(() => {
    refetch().catch((err) => console.log(err)); // eslint-disable-line
  }, [refetchFlag]);

  useEffect(() => {
    if (isAdmin) {
      getDealershipCostsCharts(Number(dealershipId)).catch((err) => console.log(err)); // eslint-disable-line
    }
  }, [isAdmin, dealershipId]);

  useEffect(() => {
    getTopics({
      dealershipId: Number(dealershipId),
      startDate: initialDate.toISOString(),
      endDate: endDate.toISOString(),
    }).catch(
      (err) => console.log(err) // eslint-disable-line
    );
  }, [dealershipId, initialDate, endDate]);

  const htmlPrompt = prompt
    .replaceAll('>', '&gt;')
    .replaceAll('<', '&lt;')
    .replaceAll('\n', '<br />')
    .replaceAll(/&lt;\S*?[^_]&gt;/g, (str) => `<span style="color: red;">${str}</span>`)
    .replaceAll(/&lt;!--[\S\s]*?--&gt;/g, (str) => `<span style="color: grey;">${str}</span>`)
    .replaceAll(/https[^\n\r<]+/g, (str) => `<span style="color: blue; text-decoration: underline">${str}</span>`)
    .replaceAll(/#+\s[^\n\r<]+/g, (str) => `<span style="font-weight: bold;">${str}</span>`);

  // eslint-disable-next-line react/no-danger
  const prettyPrompt = <div id="dummy" className="original" dangerouslySetInnerHTML={{ __html: htmlPrompt }} />;

  return (
    <Box pl={1}>
      <Box display="flex" justifyContent="flex-start" mb={2} alignItems="center">
        <Typography variant="h3">Empresa</Typography>
        <PrimaryBtn sx={{ ml: 2 }} onClick={() => navigate(-1)} variant="contained">
          Volver
        </PrimaryBtn>
      </Box>
      <Typography variant="body1">
        <b>Nombre:</b> {dealership?.name}
      </Typography>
      <Typography variant="body1">
        <b>Teléfono:</b> {dealership?.phoneNumber}
      </Typography>
      <Typography variant="body1">
        <b>Prompt</b>
      </Typography>
      <Box px={1}>
        <textarea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          style={{ width: '100%', height: '200px' }}
        />
      </Box>
      <Box py={2}>
        <FormControl sx={{ minWidth: 250, maxWidth: '100%' }}>
          <InputLabel>Funciones disponibles</InputLabel>
          <Select
            label="Funciones disponibles"
            multiple
            value={functions}
            onChange={handleChange}
            renderValue={(selected) => selected.join(', ')}
            autoWidth
          >
            {availableFunctions.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={functions.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Typography variant="body1">
        <b>Prompt para revision de chat</b>
      </Typography>
      <Box px={1}>
        <textarea
          value={chatRevisionPrompt || ''}
          onChange={(e) => setChatRevisionPrompt(e.target.value === '' ? null : e.target.value)}
          style={{ width: '100%', height: '200px' }}
        />
      </Box>
      <PrimaryBtn sx={{ mt: 2 }} onClick={handleUpdate} variant="contained">
        Actualizar
      </PrimaryBtn>
      <Typography variant="h6" pt="25px">
        Videos:
      </Typography>
      {dealership?.videos && (
        <>
          {Object.keys(dealership.videos).map((key) => (
            <div key={key}>
              <a
                href={dealership.videos?.[key]?.publicFileURL}
                target="_blank"
                rel="noreferrer"
                key={key}
                style={{ textDecoration: 'underline' }}
              >
                {key}
              </a>
            </div>
          ))}
        </>
      )}
      <Typography variant="h6" pt="25px">
        Imágenes:
      </Typography>
      {dealership?.images && (
        <>
          {Object.keys(dealership.images).map((key) => (
            <div key={key}>
              <a
                href={dealership.images?.[key]?.publicFileURL}
                target="_blank"
                rel="noreferrer"
                key={key}
                style={{ textDecoration: 'underline' }}
              >
                {key}
              </a>
              <IconButton onClick={() => handleDeleteImage(key)} color="error">
                <DeleteIcon />
              </IconButton>
              <br />
            </div>
          ))}
        </>
      )}
      <label htmlFor="file">
        <input
          id="file"
          type="file"
          accept=".jpg,.jpeg,.png"
          multiple
          onChange={(e) => {
            if (e.target.files && e.target.files.length) {
              setImagesToUpload(Array.from(e.target.files));
            } else if (e.target.files && !e.target.files.length) {
              setImagesToUpload(null);
            }
          }}
          placeholder="Seleccionar imágenes"
        />
      </label>
      <br />
      <PrimaryBtn onClick={handleUploadImages} disabled={!!getImagesError()} sx={{ my: 2 }} variant="contained">
        Subir imágenes
      </PrimaryBtn>
      {getImagesError() && <Typography variant="body1">{getImagesError()}</Typography>}
      {isAdmin && (
        <Box>
          <Typography variant="h6" pt="25px">
            Costos:
          </Typography>
          <Typography variant="body1">
            <b>Costo total:</b>{' '}
            {priceFormat.format(
              Object.values(dealershipCostsCharts?.costPerDay || {}).reduce((acc, curr) => acc + curr, 0)
            )}
          </Typography>
          <Typography variant="body1">
            <b>Costo de FloidRequests:</b> {priceFormat.format(dealershipCostsCharts?.totalFloidRequestsCost || 0)}
          </Typography>
          <ChartCard
            title="Costos por cliente en el tiempo"
            description="Costo promedio de los clientes creados un día en específico (WhatsApp, AI y floid)"
          >
            <LineChart
              series={[
                {
                  data: Object.values(dealershipCostsCharts?.averageCostPerDay || {}),
                },
              ]}
              height={290}
              xAxis={[
                {
                  data: Object.keys(dealershipCostsCharts?.averageCostPerDay || {}),
                  scaleType: 'band',
                  tickLabelStyle: {
                    angle: 45,
                    textAnchor: 'start',
                    fontSize: 12,
                  },
                },
              ]}
              yAxis={[
                {
                  label: 'Costo ($)',
                  min: 0,
                  tickMinStep: 50,
                },
              ]}
              margin={{ top: 10, bottom: 60, left: 40, right: 80 }}
              title="Costos por cliente en el tiempo"
            />
          </ChartCard>

          <ChartCard
            title="Costos promedio de un mensaje por dia"
            description="Costo promedio de un mensaje por dia (WhatsApp, AI y floid). No se consideran mensajes no enviados"
          >
            <LineChart
              series={[
                {
                  data: Object.values(dealershipCostsCharts?.averageMessageCostPerDay || {}),
                },
              ]}
              height={290}
              xAxis={[
                {
                  data: Object.keys(dealershipCostsCharts?.averageMessageCostPerDay || {}),
                  scaleType: 'band',
                  tickLabelStyle: {
                    angle: 45,
                    textAnchor: 'start',
                    fontSize: 12,
                  },
                },
              ]}
              yAxis={[
                {
                  label: 'Costo por mensaje ($)',
                  min: 0,
                  tickMinStep: 10,
                },
              ]}
              margin={{ top: 10, bottom: 60, left: 40, right: 80 }}
              title="Costos por mensaje en el tiempo"
            />
          </ChartCard>

          <ChartCard
            title="Costos por cliente"
            description="Cantidad de clientes que nos cuestan cada uno de estos rangos (WhatsApp, AI y floid)"
          >
            <BarChart
              series={[
                {
                  data: (dealershipCostsCharts?.costRangesCount || []).map((costRange) => costRange.count),
                },
              ]}
              height={290}
              xAxis={[
                {
                  data: (dealershipCostsCharts?.costRangesCount || []).map(
                    (costRange) => `<${priceFormat.format(costRange.range[1])}`
                  ),
                  scaleType: 'band',
                },
              ]}
              yAxis={[
                {
                  label: 'Cantidad de clientes',
                  min: 0,
                  tickMinStep: 1,
                },
              ]}
              margin={{ top: 10, bottom: 90, left: 40, right: 80 }}
              title="Costos promedio"
            />
          </ChartCard>

          <Typography variant="h6" py="25px">
            Filtrar fechas de estadísticas:
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Fecha inicial"
              format="DD/MMM/YYYY"
              value={initialDate}
              onChange={(value) => setInitialDate(value || dateBeginningOfMonth)}
              slotProps={{ textField: { variant: 'outlined' } }}
            />
            <DatePicker
              label="Fecha final"
              format="DD/MMM/YYYY"
              value={endDate}
              onChange={(value) => setEndDate(value || dateEndOfMonth)}
              slotProps={{ textField: { variant: 'outlined' } }}
            />
          </LocalizationProvider>
          <ChartCard
            title="Temas de conversación"
            description="Temas hablados por los clientes. Puede haber más de un tema por cliente (conversación)"
          >
            <BarChart
              series={[
                {
                  data: conversationTopics ? Object.values(conversationTopics.topics) : [],
                },
              ]}
              height={290}
              xAxis={[
                {
                  data: conversationTopics ? Object.keys(conversationTopics.topics) : [],
                  scaleType: 'band',
                },
              ]}
              yAxis={[
                {
                  label: 'Cantidad de conversaciones',
                  min: 0,
                  tickMinStep: 1,
                },
              ]}
              margin={{ top: 10, bottom: 90, left: 40, right: 80 }}
            />
          </ChartCard>
          <ChartCard title="Motivos de término" description="Motivos de término de conversaciones">
            <BarChart
              series={[
                {
                  data: conversationTopics ? Object.values(conversationTopics.endReasons) : [],
                },
              ]}
              height={290}
              xAxis={[
                {
                  data: conversationTopics ? Object.keys(conversationTopics.endReasons) : [],
                  scaleType: 'band',
                },
              ]}
              yAxis={[
                {
                  label: 'Cantidad de conversaciones',
                  min: 0,
                  tickMinStep: 1,
                },
              ]}
              margin={{ top: 10, bottom: 90, left: 40, right: 80 }}
            />
          </ChartCard>
          <ChartCard title="Puntajes de conversaciones" description="Puntajes al vendedor, obtenido con AI">
            <BarChart
              series={[
                {
                  data: conversationTopics ? Object.values(conversationTopics.aiScores) : [],
                },
              ]}
              height={290}
              xAxis={[
                {
                  data: conversationTopics ? Object.keys(conversationTopics.aiScores) : [],
                  scaleType: 'band',
                },
              ]}
              yAxis={[
                {
                  label: 'Cantidad de conversaciones',
                  min: 0,
                  tickMinStep: 1,
                },
              ]}
              margin={{ top: 10, bottom: 90, left: 40, right: 80 }}
            />
          </ChartCard>

          <Typography variant="h6" pt="25px">
            Chat fuera de whatsapp:{' '}
            {dealership?.pusherIdentification ? (
              <a href={`/demochat/${dealership?.pusherIdentification}`} target="_blank" rel="noreferrer">
                Ir al chat
              </a>
            ) : (
              'No disponible'
            )}
          </Typography>
        </Box>
      )}
      <Typography variant="body1">
        <b>WhatsApp Tiers:</b>{' '}
        {dealership?.phoneNumberTier?.map((tier) => `${tier.split('T')[0]} ${tier.split(':').at(-1)}`).join(', ')}
      </Typography>
      <Typography variant="body1" mt={2}>
        <b>Prompt más bonito:</b>
      </Typography>
      <Box px={4}>{prettyPrompt}</Box>
      <Box display="flex">
        <Switch checked={showInstructions} onChange={() => setShowInstructions(!showInstructions)} />
        <Typography variant="h6">Ver instrucciones</Typography>
        <Box>{showInstructions && PROMPT_HELP_TEXT}</Box>
      </Box>
    </Box>
  );
};

export default Dealerships;
