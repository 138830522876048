import React, { FC, useEffect } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import DealershipSelector from 'components/common/DealershipsSelector';
import useCurrentUser from 'hooks/useCurrentUser';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import moment from 'moment';
import {
  useGetClientsMetricsMutation,
  useGetMessagesMetricsMutation,
  useGetResponseTimesMetricsMutation,
  useGetFollowUpResponseTimesMetricsMutation,
} from 'services/api/metrics.api';
import { useGetDealershipBrandsQuery, useGetDealershipSourcesQuery } from 'services/api/user.api';
import type {
  DealershipClientsMetrics,
  DealershipMessagesMetrics,
  DealershipResponseTimesMetrics,
  DealershipFollowUpResponseTimesMetrics,
} from 'services/types/metrics';

import styles from './Dashboard.module.scss';
import ChartCard from './components/ChartCard';
import HardMetricCard from './components/HardMetricCard';

const minutesToString = (minutes: number | string) => {
  const mins = Number(minutes);
  if (mins < 60) {
    return `<${mins}m`;
  }
  if (mins < 1440) {
    return `<${Math.floor(mins / 60)}h`;
  }
  return `<${Math.floor(mins / 1440)}d`;
};

interface SourceProps {
  sources: string[];
  setSources: (sources: string[]) => void;
  dealershipId: number | null;
}

const SourcesSelector: FC<SourceProps> = ({ sources, setSources, dealershipId }) => {
  // TODO: Change this list depending on the user's company
  const { data: sourcesData } = useGetDealershipSourcesQuery({ dealershipId });
  const handleChange = (event: SelectChangeEvent<typeof sources>) => {
    const {
      target: { value },
    } = event;
    setSources(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <FormControl sx={{ minWidth: 250 }}>
      <InputLabel>Origen</InputLabel>
      <Select
        label="Origen"
        multiple
        value={sources}
        onChange={handleChange}
        renderValue={(selected) =>
          selected
            .map((value) => (value.length > 33 ? `${value.slice(0, 15)}...${value.slice(-15)}` : value))
            .join(', ')
        }
        autoWidth
      >
        {sourcesData?.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={sources.indexOf(name) > -1} />
            <ListItemText primary={name || '--desconocido--'} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

interface BrandsSelectorProps {
  brands: string[];
  setBrands: (sources: string[]) => void;
  dealershipId: number | null;
}

const BrandsSelector: FC<BrandsSelectorProps> = ({ brands, setBrands, dealershipId }) => {
  // TODO: Change this list depending on the user's company
  const { data: brandsData } = useGetDealershipBrandsQuery({ dealershipId });
  const handleChange = (event: SelectChangeEvent<typeof brands>) => {
    const {
      target: { value },
    } = event;
    setBrands(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <FormControl sx={{ minWidth: 250, pl: 1 }}>
      <InputLabel>Marca</InputLabel>
      <Select
        label="Marca"
        multiple
        value={brands}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
        autoWidth
      >
        {brandsData?.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={brands.indexOf(name) > -1} />
            <ListItemText primary={name || '--desconocido--'} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

interface ChartProps {
  isAdmin: boolean;
  clientMetrics?: DealershipClientsMetrics;
  messagesMetrics?: DealershipMessagesMetrics;
  responseTimesMetrics?: DealershipResponseTimesMetrics;
  followUpResponseTimesMetrics?: DealershipFollowUpResponseTimesMetrics;
}

const Charts: FC<ChartProps> = ({
  isAdmin,
  clientMetrics,
  messagesMetrics,
  responseTimesMetrics,
  followUpResponseTimesMetrics,
}: ChartProps) => {
  const clientsBySourceForChart = { ...(clientMetrics?.chartsData.clientsBySource || {}) };
  if (Object.keys(clientsBySourceForChart).length > 25) {
    const whatsappOthersKey = 'whatsapp-otros';
    clientsBySourceForChart[whatsappOthersKey] = 0;
    while (Object.keys(clientsBySourceForChart).length > 15) {
      const minKey = Object.keys(clientsBySourceForChart)
        .filter((key) => key.includes('whatsapp') && key !== whatsappOthersKey)
        .reduce(
          (a, b) => (clientsBySourceForChart[a] < clientsBySourceForChart[b] ? a : b),
          Object.keys(clientsBySourceForChart)[0]
        );
      clientsBySourceForChart[whatsappOthersKey] += clientsBySourceForChart[minKey];
      delete clientsBySourceForChart[minKey];
    }
  }
  const sourceChartData = Object.entries(clientsBySourceForChart).map((source, index) => ({
    id: index,
    value: source[1],
    label: source[0].length > 43 ? `${source[0].slice(0, 20)}...${source[0].slice(-20)}` : source[0],
  }));

  const brandChartData = Object.entries(clientMetrics?.chartsData.clientsByBrand || {}).map((brand, index) => ({
    id: index,
    value: brand[1],
    label: brand[0],
  }));

  const clientsByMessagesCount = { ...(clientMetrics?.chartsData.clientsByMessagesCount || {}) };
  delete clientsByMessagesCount[0];
  delete clientsByMessagesCount[1];
  const clientsByResponseTime = { ...(responseTimesMetrics?.chartsData.clientsByResponseTime || {}) };
  delete clientsByResponseTime[0];

  return (
    <Box>
      <Grid container direction="column">
        <ChartCard
          title="Clientes por día"
          description="Clientes que se crean cada día. Ej: 03-24 (S) es el Sábado 24 de marzo. 'Clientes en CRM' son los que se suben al sistema de la empresa"
        >
          {clientMetrics ? (
            <LineChart
              series={[
                { data: Object.values(clientMetrics.chartsData.clientsByCreationDay), label: 'Clientes' },
                {
                  data: Object.values(clientMetrics.chartsData.clientsUploadedToCRMByCreationDay),
                  label: 'Clientes en CRM',
                  valueFormatter: (value, { dataIndex }) => {
                    return `${value} (${(
                      ((value || 0) * 100) /
                      (Object.values(clientMetrics.chartsData.clientsByCreationDay)[dataIndex] || 1)
                    ).toFixed(1)}%)`;
                  },
                },
                {
                  data: Object.values(clientMetrics.chartsData.clientsWithVisitDateByCreationDay),
                  label: 'Clientes con visita agendada',
                  valueFormatter: (value, { dataIndex }) => {
                    return `${value} (${(
                      ((value || 0) * 100) /
                      (Object.values(clientMetrics.chartsData.clientsByCreationDay)[dataIndex] || 1)
                    ).toFixed(1)}%)`;
                  },
                },
              ]}
              height={400}
              xAxis={[
                {
                  scaleType: 'band',
                  data: Object.keys(clientMetrics.chartsData.clientsByCreationDay),
                  tickLabelStyle: {
                    angle: 45,
                    textAnchor: 'start',
                    fontSize: 12,
                  },
                },
              ]}
              yAxis={[
                {
                  label: 'Cantidad de clientes',
                  min: 0,
                  tickMinStep: 1,
                },
              ]}
              margin={{ top: 10, bottom: 60, left: 40, right: 80 }}
              title="Clientes por hora de entrada"
              // place legend on the right
              slotProps={{
                legend: {
                  position: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                },
              }}
            />
          ) : (
            <CircularProgress />
          )}
        </ChartCard>

        <ChartCard title="Clientes por marca" description="Clientes que se crean por marca">
          {clientMetrics ? (
            <PieChart
              skipAnimation
              label="Clientes por marca"
              title="Clientes por marca"
              series={[
                {
                  data: brandChartData,
                },
              ]}
              height={(Math.floor(brandChartData.length / 5) + 1) * 200}
            />
          ) : null}
        </ChartCard>

        <ChartCard title="Clientes por origen" description="Gráfico con la fuente de los clientes">
          {clientMetrics ? (
            <PieChart
              skipAnimation
              label="Clientes por origen"
              title="Clientes por origen"
              series={[
                {
                  data: sourceChartData,
                },
              ]}
              height={(Math.floor(sourceChartData.length / 5) + 1) * 200}
            />
          ) : null}
        </ChartCard>
        {clientMetrics && clientMetrics.chartsData.clientsByCreditStatus ? (
          <ChartCard
            title="Clientes por estado de crédito"
            description="Clientes según el estado de crédito luego de la revisión de la financiera"
          >
            <PieChart
              skipAnimation
              label="Clientes por estado de crédito"
              title="Clientes por estado de crédito"
              series={[
                {
                  data: Object.entries(clientMetrics.chartsData.clientsByCreditStatus).map((creditStatus, index) => ({
                    id: index,
                    value: creditStatus[1],
                    label: creditStatus[0],
                  })),
                },
              ]}
              height={200}
            />
          </ChartCard>
        ) : null}

        <ChartCard
          title="Clientes por horario de llegada"
          description="Clientes que llegan cada hora. Ej: El valor 15 corresponde a la cantidad de clientes que se crean entre las 15:00 y las 15:59"
        >
          {clientMetrics ? (
            <LineChart
              series={[{ data: Object.values(clientMetrics.chartsData.clientsByCreationHour) }]}
              height={290}
              xAxis={[{ data: Object.keys(clientMetrics.chartsData.clientsByCreationHour), scaleType: 'band' }]}
              yAxis={[
                {
                  label: 'Cantidad de clientes',
                },
              ]}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
              title="Clientes por hora de entrada"
            />
          ) : (
            <CircularProgress />
          )}
        </ChartCard>

        <ChartCard
          title="Mensajes por día"
          description="Mensajes que se enviaron cada día. Ej: 03-24 (S) es el Sábado 24 de marzo"
        >
          {messagesMetrics ? (
            <BarChart
              series={[
                {
                  data: Object.values(messagesMetrics.chartsData.messagesPerDayInOfficeHours),
                  stack: '1',
                  label: 'Horario Laboral',
                },
                {
                  data: Object.values(messagesMetrics.chartsData.messagesPerDayOutOfOfficeHours),
                  stack: '1',
                  label: 'Fuera de Horario Laboral',
                },
              ]}
              height={400}
              xAxis={[
                {
                  data: Object.keys(messagesMetrics.chartsData.messagesPerDayInOfficeHours),
                  scaleType: 'band',
                  tickLabelStyle: {
                    angle: 45,
                    textAnchor: 'start',
                    fontSize: 12,
                  },
                },
              ]}
              margin={{ top: 10, bottom: 60, left: 40, right: 80 }}
              colors={['#6499e9', '#f02b2c']}
            />
          ) : (
            <CircularProgress />
          )}
        </ChartCard>
        <ChartCard
          title="Mensajes por hora"
          description="Mensajes que se envían cada hora. Ej: El valor 15 corresponde a la cantidad de mensajes enviados entre las 15:00 y las 15:59"
        >
          {messagesMetrics ? (
            <LineChart
              series={[{ data: Object.values(messagesMetrics.chartsData.messagesByCreationHour) }]}
              height={290}
              xAxis={[{ data: Object.keys(messagesMetrics.chartsData.messagesByCreationHour), scaleType: 'band' }]}
              yAxis={[
                {
                  label: 'Cantidad de mensajes',
                },
              ]}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
              title="Mensajes que se envían cada hora"
            />
          ) : (
            <CircularProgress />
          )}
        </ChartCard>

        <ChartCard
          title="Cantidad de mensajes que envían los clientes"
          description="Ignora los clientes con teléfonos inválidos (0 mensajes) y también los que nunca contestaron (1 mensaje)"
        >
          {clientMetrics ? (
            <BarChart
              series={[{ data: Object.values(clientsByMessagesCount) }]}
              height={290}
              xAxis={[{ data: Object.keys(clientsByMessagesCount), scaleType: 'band' }]}
              yAxis={[
                {
                  label: 'Cantidad de clientes',
                  tickMinStep: 1,
                },
              ]}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
              title="Clientes por cantidad de mensajes"
            />
          ) : (
            <CircularProgress />
          )}
        </ChartCard>
        <ChartCard
          title="Tiempo de primera respuesta de los clientes"
          description="Solo considera clientes en que Zeller envía el primer mensaje y que luego contestan. Para los que no contestan ver el indicador del inicio. Para las conversaciones iniciadas por los clientes, ver el gráfico de 'Clientes por Origen' y revisar los que dicen origen 'whatsapp'"
        >
          {responseTimesMetrics ? (
            <BarChart
              series={[
                {
                  data: Object.values(clientsByResponseTime).map(
                    (value) =>
                      (value * 100) / (Object.values(clientsByResponseTime).reduce((acc, val) => acc + val, 0) || 1)
                  ),
                },
              ]}
              height={290}
              xAxis={[{ data: Object.keys(clientsByResponseTime).map(minutesToString), scaleType: 'band' }]}
              yAxis={[
                {
                  label: 'Porcentaje de clientes',
                },
              ]}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
              title="Tiempo de respuestas de los clientes"
            />
          ) : (
            <CircularProgress />
          )}
        </ChartCard>
        <ChartCard
          title="Tiempo de respuestas de los clientes a los followUps"
          description="Gráfico 1: Tiempo de respuesta a mensajes de follow up. Gráfico 2: Cantidad de followUps por cada tipo. Si el mensaje no tiene respuesta en 48 hrs se considera como 'No responde'. Toma en cuenta los mensajes de follow ups enviados en el rango de fechas seleccionado"
        >
          {followUpResponseTimesMetrics &&
          isAdmin &&
          followUpResponseTimesMetrics.chartsData.followUpsWithResponseTimes.length > 0 ? (
            <Box display="flex" mt={2}>
              <Box sx={{ flex: 3 }}>
                <BarChart
                  series={followUpResponseTimesMetrics.chartsData.followUpsWithResponseTimes.map((followUp) => ({
                    data: [
                      followUp.respond10Minutes,
                      followUp.respond1Hour - followUp.respond10Minutes,
                      followUp.respond6Hour - followUp.respond1Hour,
                      followUp.respond24Hour - followUp.respond6Hour,
                      followUp.respond48Hour - followUp.respond24Hour,
                      followUp.didnotRespond,
                    ],
                    label: followUp.followUpConfigId,
                  }))}
                  height={290}
                  xAxis={[{ data: ['<10m', '<1hr', '<6hr', '<24hr', '<48hr', 'No responde'], scaleType: 'band' }]}
                  yAxis={[
                    {
                      label: 'Cantidad de clientes',
                    },
                  ]}
                  margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                  title="Tiempo de respuestas de los clientes a los followUps"
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <BarChart
                  series={[
                    {
                      data: followUpResponseTimesMetrics.chartsData.followUpsWithResponseTimes.map(
                        (followUp) => followUp.totalClients - followUp.didnotRespond
                      ),
                      label: 'Clientes que responden',
                      stack: '1',
                    },
                    {
                      data: followUpResponseTimesMetrics.chartsData.followUpsWithResponseTimes.map(
                        (followUp) => followUp.didnotRespond
                      ),
                      label: 'Clientes sin respuesta',
                      stack: '1',
                    },
                  ]}
                  height={290}
                  xAxis={[
                    {
                      data: followUpResponseTimesMetrics.chartsData.followUpsWithResponseTimes.map(
                        (followUp) => followUp.followUpConfigId
                      ),
                      scaleType: 'band',
                    },
                  ]}
                  yAxis={[
                    {
                      label: 'Cantidad de followUps',
                    },
                  ]}
                  margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                  title="Cantidad de followUps enviados"
                />
              </Box>
            </Box>
          ) : (
            <Typography mt={4}>No disponible todavía</Typography>
          )}
        </ChartCard>
      </Grid>
    </Box>
  );
};

Charts.defaultProps = {
  clientMetrics: undefined,
  messagesMetrics: undefined,
  responseTimesMetrics: undefined,
  followUpResponseTimesMetrics: undefined,
};

const Dashboard = () => {
  const currentUser = useCurrentUser();

  // Create a component for a date range selector
  const currentDate = new Date();
  const dateBeginningOfMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
  const dateEndOfMonth = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));

  const [initialDate, setInitialDate] = React.useState<moment.Moment>(dateBeginningOfMonth);
  const [endDate, setEndDate] = React.useState<moment.Moment>(dateEndOfMonth);
  const [dealershipId, setDealershipId] = React.useState<number | null>(
    currentUser?.isAdmin ? -1 : currentUser?.dealershipIds[0] || null
  );
  const [sources, setSources] = React.useState<string[]>([]);
  const [brands, setBrands] = React.useState<string[]>([]);

  const [getClientsMetrics, { data: clientsMetrics }] = useGetClientsMetricsMutation();
  const [getMessagesMetrics, { data: messagesMetrics }] = useGetMessagesMetricsMutation();
  const [getResponseTimesMetrics, { data: responseTimesMetrics }] = useGetResponseTimesMetricsMutation();
  const [getFollowUpResponseTimesMetrics, { data: followUpResponseTimesMetrics }] =
    useGetFollowUpResponseTimesMetricsMutation();
  const bigHardMetrics =
    clientsMetrics && messagesMetrics
      ? [
          {
            type: 'Cantidad de clientes',
            data: clientsMetrics.hardMetrics.clientsCount,
            tooltip: 'Cantidad de clientes creados en el rango de fechas seleccionado',
          },
          {
            type: 'Clientes en CRM',
            data:
              (clientsMetrics.hardMetrics.clientsUploadedToCRM || 0) / (clientsMetrics.hardMetrics.clientsCount || 1),
            secondaryData: clientsMetrics.hardMetrics.clientsUploadedToCRM,
            tooltip:
              'Clientes que se enviaron al CRM de la empresa (de los creados en el rango de fechas seleccionado)',
            percentage: true,
          },
          {
            type: 'Clientes que agendan visita',
            data:
              (clientsMetrics.hardMetrics.clientsWithVisitDate || 0) / (clientsMetrics.hardMetrics.clientsCount || 1) ||
              0,
            secondaryData: clientsMetrics.hardMetrics.clientsWithVisitDate,
            tooltip: 'Clientes que agendaron visita (de los creados en el rango de fechas seleccionado)',
            percentage: true,
          },
          {
            type: 'Clientes contactados',
            data:
              (clientsMetrics.hardMetrics.clientsContactedCount || 0) / (clientsMetrics.hardMetrics.clientsCount || 1),
            secondaryData: clientsMetrics.hardMetrics.clientsContactedCount,
            tooltip: 'Cantidad de clientes que pudieron ser contactados',
            percentage: true,
          },
        ]
      : [];
  const smallHardMetrics =
    clientsMetrics && messagesMetrics
      ? [
          {
            type: 'Clientes no contactados',
            data:
              (clientsMetrics.hardMetrics.clientsWithWrongPhoneCount || 0) /
              (clientsMetrics.hardMetrics.clientsCount || 1),
            secondaryData: clientsMetrics.hardMetrics.clientsWithWrongPhoneCount,
            tooltip: 'Clientes que no pudieron ser contactados. Probablemente tienen un teléfono incorrecto',
            percentage: true,
          },
          {
            type: 'Clientes que respondieron',
            data:
              (clientsMetrics.hardMetrics.clientsThatAnsweredCount || 0) /
              (clientsMetrics.hardMetrics.clientsCount || 1),
            secondaryData: clientsMetrics.hardMetrics.clientsThatAnsweredCount,
            tooltip:
              'Clientes que enviaron algún mensaje. Toma en cuenta los creados dentro del rango de fechas seleccionado',
            percentage: true,
          },

          {
            type: 'Mensajes por cliente',
            data: clientsMetrics.hardMetrics.averageMessagesPerClient,
            tooltip:
              'Promedio de mensajes en las conversaciones. Considera solo los clientes creados en ese rango de fechas',
          },
          {
            type: 'Cantidad de mensajes',
            data: messagesMetrics.hardMetrics.messagesCount,
            tooltip: 'Cantidad de mensajes en el rango de fechas seleccionado',
          },
          {
            type: 'Mensajes de clientes',
            data: messagesMetrics.hardMetrics.messagesFromUserCount,
            tooltip: 'Cantidad de mensajes enviados por clientes en el rango de fechas seleccionado',
          },
          {
            type: 'Fuera de horario laboral',
            data:
              (messagesMetrics.hardMetrics.totalMessagesOutOfOfficeHours || 0) /
              (messagesMetrics.hardMetrics.messagesCount || 1),
            secondaryData: messagesMetrics.hardMetrics.totalMessagesOutOfOfficeHours,
            tooltip:
              'Mensajes enviados fuera del horario laboral (lunes a viernes de 9 a 18) en el rango de fechas seleccionado',
            percentage: true,
          },
          {
            type: 'Fotos/documentos',
            data: messagesMetrics.hardMetrics.messagesWithFileCount,
            tooltip: 'Cantidad de fotos y documentos enviados a clientes en el rango de fechas seleccionado',
          },
        ]
      : [];

  useEffect(() => {
    getClientsMetrics({
      startDate: initialDate.toISOString(),
      endDate: endDate.toISOString(),
      dealershipId: dealershipId || null,
      sources,
      brands,
    }).catch((err) => console.error(err)); // eslint-disable-line no-console
    getMessagesMetrics({
      startDate: initialDate.toISOString(),
      endDate: endDate.toISOString(),
      dealershipId: dealershipId || null,
      sources,
      brands,
    }).catch((err) => console.error(err)); // eslint-disable-line no-console
    getResponseTimesMetrics({
      startDate: initialDate.toISOString(),
      endDate: endDate.toISOString(),
      dealershipId: dealershipId || null,
      sources,
      brands,
    }).catch((err) => console.error(err)); // eslint-disable-line no-console
    getFollowUpResponseTimesMetrics({
      startDate: initialDate.toISOString(),
      endDate: endDate.toISOString(),
      dealershipId: dealershipId || null,
      sources,
      brands,
    }).catch((err) => console.error(err)); // eslint-disable-line no-console
  }, [initialDate, endDate, dealershipId, sources, brands]);

  return (
    <Box m={2}>
      <Typography variant="h4" mb={2}>
        Dashboard
      </Typography>

      <Box ml={2} mb={3} display="flex">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Fecha inicial"
            format="DD/MMM/YYYY"
            value={initialDate}
            onChange={(value) => setInitialDate(value || dateBeginningOfMonth)}
            slotProps={{ textField: { variant: 'outlined' } }}
            className={styles.marginRight2}
          />
          <DatePicker
            label="Fecha final"
            format="DD/MMM/YYYY"
            value={endDate}
            onChange={(value) => setEndDate(value || dateEndOfMonth)}
            slotProps={{ textField: { variant: 'outlined' } }}
            className={styles.marginRight2}
          />
        </LocalizationProvider>
        <DealershipSelector
          dealershipId={dealershipId}
          setDealershipId={(value: number) => {
            setSources([]);
            setDealershipId(value);
          }}
          disableAllDealershipsOption={!currentUser?.isAdmin}
        />
        <SourcesSelector sources={sources} setSources={setSources} dealershipId={dealershipId} />
        <BrandsSelector brands={brands} setBrands={setBrands} dealershipId={dealershipId} />
      </Box>
      <Box>
        <Typography variant="h4">Resumen</Typography>
        <Grid container>
          <Grid item xs={12} xl={8}>
            <Grid container>
              {bigHardMetrics.map((metric) => (
                <Grid item xs={12} md={6} key={metric.type} p={1}>
                  <HardMetricCard {...metric} isBigSize />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} xl={4}>
            <Grid container>
              {smallHardMetrics.map((metric) => (
                <Grid item xs={12} md={6} key={metric.type} p={1}>
                  <HardMetricCard {...metric} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Charts
        isAdmin={currentUser?.isAdmin ?? false}
        clientMetrics={clientsMetrics}
        messagesMetrics={messagesMetrics}
        responseTimesMetrics={responseTimesMetrics}
        followUpResponseTimesMetrics={followUpResponseTimesMetrics}
      />
    </Box>
  );
};

export default Dashboard;
