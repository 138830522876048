import React from 'react';

import { Select, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { useGetCarsQuery } from 'services/api/cars.api';
import { useGetDealershipsQuery } from 'services/api/dealerships.api';
import priceFormat from 'services/format/priceFormat';

const Cars = () => {
  const { data: dealerships } = useGetDealershipsQuery(null);
  const [dealershipId, setDealershipId] = React.useState<number>(0);
  const [isUsed, setIsUsed] = React.useState<string>('null');
  const { data: cars } = useGetCarsQuery({ dealershipId, isUsed });

  const columns: GridColDef[] = [
    { field: 'id', width: 70 },
    { field: 'dealershipId' },
    { field: 'referenceId' },
    { field: 'brand', width: 130 },
    { field: 'model', width: 130 },
    { field: 'version', width: 250 },
    { field: 'year', width: 70 },
    { field: 'km', width: 80 },
    {
      field: 'price',
      width: 120,
      valueFormatter: (value) => priceFormat.format(value as number),
    },
    {
      field: 'monthlyFee',
      width: 100,
      valueFormatter: (value) => priceFormat.format(value as number),
    },
    { field: 'transmission', width: 120 },
    { field: 'carType', width: 120 },
    { field: 'category', width: 120 },
    { field: 'fuelType', width: 130 },
    { field: 'isUsed', width: 70 },
    { field: 'branchOfficeDirection', width: 250 },
    { field: 'color', width: 150 },
    { field: 'createdAt', width: 200 },
    { field: 'updatedAt', width: 200 },
    {
      field: 'link',
      width: 100,
      renderCell: (params) => (
        <a href={params.value as string} target="_blank" rel="noreferrer">
          Ver auto
        </a>
      ),
    },
    {
      field: 'image',
      width: 100,
      renderCell: (params) => (
        <a href={params.value as string} target="_blank" rel="noreferrer">
          Ver foto
        </a>
      ),
    },
  ];

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" m={2} alignItems="center">
        <Typography variant="h4">Lista de Autos</Typography>
        <Box>
          <Select value={isUsed} onChange={(e) => setIsUsed(e.target.value)}>
            <MenuItem value="null">Usados y Nuevos</MenuItem>
            <MenuItem value="true">Usados</MenuItem>
            <MenuItem value="false">Nuevos</MenuItem>
          </Select>
          <Select value={dealershipId} onChange={(e) => setDealershipId(e.target.value as number)}>
            <MenuItem value={0}>Todas las empresas</MenuItem>
            {dealerships?.map((dealership) => (
              <MenuItem key={dealership.id} value={dealership.id}>
                ({dealership.id}) {dealership.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box sx={{ height: 'calc(100vh - 100px)', color: 'black' }}>
        {cars && <DataGrid rows={cars} columns={columns} />}
      </Box>
    </Box>
  );
};

export default Cars;
