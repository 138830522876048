import React, { FC, useEffect } from 'react';

import { FilterAlt } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import useCurrentUser from 'hooks/useCurrentUser';
import { useGetCreditStatusMutation, useUpdateEditableAttributesMutation } from 'services/api/clients.api';
import { Client } from 'services/types/client';

import TemplateMessageModal from './TemplateMessageModal';

interface Props {
  client: Client;
  refetch: () => void;
  setIsFilteredConversation: (isFiltered: boolean) => void;
  isFilteredConversation: boolean;
}

const ChatHeader: FC<Props> = ({ client, refetch, setIsFilteredConversation, isFilteredConversation }) => {
  const currentUser = useCurrentUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openScheduleTemplateModal, setOpenScheduleTemplateModal] = React.useState(false);
  const [updateEditableAttributes] = useUpdateEditableAttributesMutation();
  const [getCreditStatus, { data: creditStatus }] = useGetCreditStatusMutation();

  const openDropdownMenu = Boolean(anchorEl);
  const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleEnableAutomaticResponses = () => {
    updateEditableAttributes({
      id: client.id,
      body: { disabledAutomaticResponses: !client.disabledAutomaticResponses },
    })
      .then(() => {
        refetch();
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error updating client');
      });
  };

  const handleGetCreditStatus = () => {
    getCreditStatus(client.id)
      .then(() => {
        refetch();
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error getting credit status');
      });
  };

  useEffect(() => {
    if (creditStatus) {
      alert(JSON.stringify(creditStatus)); // eslint-disable-line no-alert
    }
  }, [creditStatus]);

  return (
    <Grid item xs={12} p={2} style={{ backgroundColor: 'white', height: '100%' }}>
      <TemplateMessageModal
        clientId={client.id}
        open={openScheduleTemplateModal}
        setOpen={setOpenScheduleTemplateModal}
        refetch={refetch}
        schedule={false}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Tooltip title="Recargar conversación" arrow>
            <IconButton onClick={refetch} size="large">
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <IconButton onClick={handleDropdownClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            open={openDropdownMenu}
            onClose={handleDropdownClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              onClick={() => {
                setOpenScheduleTemplateModal(true);
              }}
            >
              Enviar Template
            </MenuItem>
            <MenuItem onClick={handleEnableAutomaticResponses}>
              {client.disabledAutomaticResponses ? 'Habilitar' : 'Deshabilitar'} IA
            </MenuItem>
            {client.creditSimulations && client.creditSimulations.length > 0 && (
              <MenuItem onClick={handleGetCreditStatus}>Estado de simulación de crédito</MenuItem>
            )}
          </Menu>
          {currentUser?.isAdmin && (
            <Tooltip title="Filtrar conversación" arrow>
              <IconButton onClick={() => setIsFilteredConversation(!isFilteredConversation)} size="large">
                <FilterAlt
                  sx={{
                    color: isFilteredConversation ? 'primary.main' : 'inherit',
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default ChatHeader;
