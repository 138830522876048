import React from 'react';
import { Link } from 'react-router-dom';

import { RemoveRedEyeOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { useGetDealershipsQuery } from 'services/api/dealerships.api';

const Dealerships = () => {
  const { data: dealerships } = useGetDealershipsQuery(null);

  return (
    <Box>
      <Box display="flex" justifyContent="flex-start" m={2} alignItems="center">
        <Typography variant="h4">Lista de Empresas</Typography>
      </Box>
      <TableContainer
        sx={{ height: 'calc(100vh - 100px)', overflow: 'auto', backgroundColor: 'white', borderRadius: 4, p: 2 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nombre empresa</TableCell>
              <TableCell>Unidad de negocio</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>Demochat</TableCell>
              <TableCell>Modelo AI</TableCell>
              <TableCell>Fecha de creación</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dealerships?.map((dealership) => (
              <TableRow key={dealership.id}>
                <TableCell>{dealership.id}</TableCell>
                <TableCell>{dealership.company?.name}</TableCell>
                <TableCell>{dealership.name}</TableCell>
                <TableCell>{dealership.phoneNumber}</TableCell>
                <TableCell>{dealership.pusherIdentification}</TableCell>
                <TableCell>{dealership.openAIModel}</TableCell>
                <TableCell>{dealership.createdAt.toLocaleString('es-CL')}</TableCell>
                <TableCell>
                  <Link to={`/dealerships/${dealership.id}`}>
                    <Button>
                      <RemoveRedEyeOutlined />
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Dealerships;
