import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';

import {
  Client,
  ClientWithLastMessage,
  EditableAdminClientAttributes,
  EditableClientAttributes,
} from 'services/types/client';

import { TemplateResponse } from '../types/message';
import baseApi from './baseApi';

const clientsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getClients: build.query({
      query: (params: {
        paginationModel: { page: number; pageSize: number };
        filterModel: GridFilterModel;
        sortModel: GridSortModel;
        searchTerm?: string;
        dealershipId?: number;
      }) => ({
        url: `/clients?paginationModel=${JSON.stringify(params.paginationModel)}&filterModel=${JSON.stringify(
          params.filterModel
        )}&sortModel=${JSON.stringify(params.sortModel)}&searchTerm=${params.searchTerm}&dealershipId=${
          (params.dealershipId || 0) > 0 ? params.dealershipId : ''
        }`,
        method: 'GET',
      }),
      transformResponse: (response: { clients: Client[]; total: number }) => {
        return {
          clients: response.clients,
          total: response.total,
        };
      },
    }),
    getClientsFullList: build.mutation({
      query: () => ({
        url: '/clients/fullList',
        method: 'GET',
      }),
      transformResponse: (response: Client[]) => response,
    }),
    getClient: build.query({
      query: (id: number) => ({
        url: `/clients/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Client): Client => response,
    }),
    getTemplates: build.query({
      query: (clientId: number) => ({
        url: `/clients/${clientId}/templates`,
        method: 'GET',
      }),
      transformResponse: (response: TemplateResponse) => response,
    }),
    getCreditStatus: build.mutation({
      query: (clientId: number) => ({
        url: `/clients/${clientId}/credit-status`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
    }),

    updateEditableAttributes: build.mutation({
      query: (props: { id: number; body: EditableClientAttributes }) => ({
        url: `/clients/${props.id}/editable-attributes`,
        method: 'PATCH',
        body: props.body,
      }),
      transformResponse: (response: Client): Client => response,
    }),
    updateAdminEditableAttributes: build.mutation({
      query: (props: { id: number; body: EditableAdminClientAttributes }) => ({
        url: `/clients/${props.id}`,
        method: 'PATCH',
        body: props.body,
      }),
      transformResponse: (response: Client): Client => response,
    }),
    adminSearchClientByMessage: build.query({
      query: (query: string) => ({
        url: '/admin/clients/search',
        method: 'GET',
        params: {
          query,
        },
      }),
      transformResponse: (response: ClientWithLastMessage[]) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetClientQuery,
  useGetClientsQuery,
  useGetClientsFullListMutation,
  useGetTemplatesQuery,
  useGetCreditStatusMutation,
  useUpdateEditableAttributesMutation,
  useUpdateAdminEditableAttributesMutation,
  useAdminSearchClientByMessageQuery,
} = clientsApi;
