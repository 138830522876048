import { Car } from 'services/types/car';

import baseApi from './baseApi';

const carsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCars: build.query({
      query: (params: { dealershipId?: number; isUsed?: string }) => ({
        url: `/admin/cars?dealershipId=${params.dealershipId}&isUsed=${params.isUsed}`,
        method: 'GET',
      }),
      transformResponse: (response: Car[]) => response,
    }),
    getCar: build.query({
      query: (id: number) => ({
        url: `/admin/cars/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Car) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetCarsQuery, useGetCarQuery } = carsApi;
