import React, { FC } from 'react';

import Typography from '@mui/material/Typography';

import type { ExecuteSQLToolArguments } from 'services/types/analysis';

import MessageWrapper from './MessageWrapper';
import WhereFiltersComponent from './WhereFiltersComponent';

interface Props {
  args: string;
}

const ExecuteSQLToolComponent: FC<Props> = ({ args }: Props) => {
  const argumentsObject = JSON.parse(args) as ExecuteSQLToolArguments;
  return (
    <MessageWrapper caption="Analizar Base de Datos" align="left">
      <Typography>Campos a utilizar: {argumentsObject.fieldSelectors.join(', ')}</Typography>
      {argumentsObject.aggregatorSelectors && argumentsObject.aggregatorSelectors.length > 0 && (
        <Typography>
          Campos de agrupación:{' '}
          {argumentsObject.aggregatorSelectors
            ?.map(({ field, aggregatorFunction }) => `${aggregatorFunction}(${field})`)
            .join(', ')}
        </Typography>
      )}
      <WhereFiltersComponent whereFilters={argumentsObject.where || []} />
      {argumentsObject.group && argumentsObject.group.length > 0 && (
        <Typography>Agrupar: {argumentsObject.group?.join(', ')}</Typography>
      )}
      {argumentsObject.order && (
        <Typography>
          Ordenar por: campo #{argumentsObject.order.fieldIndex} {argumentsObject.order.order}
        </Typography>
      )}
      {argumentsObject.limit && <Typography>Límite de resultados: {argumentsObject.limit}</Typography>}
    </MessageWrapper>
  );
};

export default ExecuteSQLToolComponent;
