import React, { FC } from 'react';

import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { useGetUserDealershipsQuery } from 'services/api/user.api';

interface Props {
  dealershipId: number | null;
  setDealershipId: (dealershipId: number) => void;
  disableAllDealershipsOption?: boolean;
}

const DealershipSelector: FC<Props> = ({ dealershipId, setDealershipId, disableAllDealershipsOption }) => {
  const { data: dealerships } = useGetUserDealershipsQuery(null);

  if (!dealerships) {
    return null;
  }

  return (
    <FormControl sx={{ minWidth: 250, paddingRight: 1 }}>
      <InputLabel id="dealership-select-label">Unidad de negocio</InputLabel>
      <Select
        label="Unidad de negocio"
        variant="outlined"
        onChange={(e) => setDealershipId(Number(e.target.value))}
        value={dealershipId}
        autoWidth
      >
        {!disableAllDealershipsOption && <MenuItem value="-1">Todas las unidades de negocio</MenuItem>}
        {dealerships?.map((dealership) => (
          <MenuItem key={dealership.id} value={dealership.id}>
            {dealership.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

DealershipSelector.defaultProps = {
  disableAllDealershipsOption: false,
};

export default DealershipSelector;
